
.WBrowser {
  min-height: 100vh;
}

.propertyList {
  padding-top: 8px;
  padding-bottom: 8px;

}

.propertyList .title {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  box-sizing: border-box;
  list-style: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 48px;
  
  padding-left: 16px;
  padding-right: 16px;
}

.property {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
}

.propName {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.propValue {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.54);
}

.propLink {
  text-decoration: underline;
  color: rgba(0,0,200,0.54);
  cursor: pointer;
}